
import React from 'react';
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";

export default ({ id = "email1" }) => {
    return (<div>
<SectionHeading>Várandósság</SectionHeading>

    </div>
    );
};
